import { IColumn, ICommandBarItemProps, PanelType, SearchBox, SelectionMode, TextField, MessageBar, MessageBarType, PrimaryButton, IContextualMenuProps, Dropdown, IDropdownOption, Dialog, DialogType, DialogFooter, DefaultButton } from '@fluentui/react';
import React, { CSSProperties } from 'react';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import ListComponent from '../components/ListComponent';
import styles from '../styles/App.module.scss';
import { ApplicationContext } from '../contexts/ApplicationContext';
import { FormMode } from '../utils/Enums';
import useObject from '../hooks/useObject';
import { IServiceLetter, ITaxGroup } from '../models';
import { FormatCurrency, SaveFileAs } from '../utils/Helpers';
import EditableNumberTextField from '../components/EditableNumberTextField';
import { IExportRequest, IServiceLetterRequest, IShareControlRequest } from '../services/ExportService';
import ConfirmablePanel from '../components/ConfirmablePanel';
import FreezableDetailsList from '../components/FreezableDetailsList';
import { IExport } from '../models/IExport';

interface IServiceLetterProps
{

}

export const ServiceLetter: React.FunctionComponent<IServiceLetterProps> = (props: React.PropsWithChildren<IServiceLetterProps>) => {
    const navigate: NavigateFunction = useNavigate();  
    const { Services } = React.useContext(ApplicationContext);

    const [allServiceletters, setAllServiceLetters] = React.useState<IServiceLetter[]>([]);  
    const [serviceLetters, setServiceLetters] = React.useState<IServiceLetter[]>([]);  
    const [selected, setSelected] = React.useState<IServiceLetter | undefined>(undefined);
    const [isLoading, setIsLoading] = React.useState<boolean>(true);
    const [showPanel, setShowPanel] = React.useState<boolean>(false);
    const { value: entity, updateValue: updateEntity, setValue: setValue } = useObject<IServiceLetter>({type: "I", amount: 0, interests: 0, interestsExpense: 0, elsalgInclRenter: 0} as IServiceLetter);
    
    const [formMode, setFormMode] = React.useState<FormMode>(FormMode.New);
    const [taxgroups, setTaxGroups] = React.useState<ITaxGroup[]>([]);  
    
    const [exportResult, setExportResult] = React.useState<IExport>();
    
    // React.useEffect(() => {
    //   if(entity.amount != undefined && entity.interests != undefined && entity.interestsExpense != undefined)
    //   {
    //     updateEntity({elsalgInclRenter: entity.amount + (entity.interests - entity.interestsExpense)});
    //   }
    // }, [entity.amount, entity.interests, entity.interestsExpense]);

    const COLUMNS = [
        {
          key: 'year',
          name: 'Skatteår',
          fieldName: 'year',
          minWidth: 100,
          maxWidth: 100,
          isResizable: false
        },
        {
          key: 'amount',
          name: 'Elsalg i alt',
          fieldName: 'amount',
          minWidth: 150,
          maxWidth: 150,
          isResizable: false,
          onRender: (item: IServiceLetter) => {
            return FormatCurrency(item.amount);
          }
        },
        {
          key: 'interests',
          name: 'Renteindtægter',
          fieldName: 'interests',
          minWidth: 150,
          maxWidth: 150,
          isResizable: false,
          onRender: (item: IServiceLetter) => {
            return FormatCurrency(item.interests);
          }
        },
        {
          key: 'interestsExpense',
          name: 'Renteudgifter',
          fieldName: 'interestsExpense',
          minWidth: 150,
          maxWidth: 250,
          isResizable: true,
          onRender: (item: IServiceLetter) => {
            return FormatCurrency(item.interestsExpense);
          }
        }, 
        {
            key: 'depreciation',
            name: 'Afskrivninger',
            fieldName: 'depreciation',
            minWidth: 150,
            maxWidth: 250,
            isResizable: true,
            onRender: (item: IServiceLetter) => {
              return FormatCurrency(item.depreciation);
            }
          },    
          {
            key: 'result',
            name: 'Resultat',
            fieldName: 'result',
            minWidth: 150,
            maxWidth: 250,
            isResizable: true,
            onRender: (item: IServiceLetter) => {
              return FormatCurrency(item.result);
            }
          },       
          {
            key: 'carriedForwardDeficit',
            name: 'Overført underskud',
            fieldName: 'carriedForwardDeficit',
            minWidth: 150,
            maxWidth: 250,
            isResizable: true,
            onRender: (item: IServiceLetter) => {
              return FormatCurrency(item.carriedForwardDeficit);
            }
          },                     
          {
            key: 'extra1',
            name: 'Ekstra 1',
            fieldName: 'extra1',
            minWidth: 150,
            maxWidth: 250,
            isResizable: true,
            onRender: (item: IServiceLetter) => {
              return FormatCurrency(item.extra1);
            }
          },
          {
            key: 'extra2',
            name: 'Ekstra 2',
            fieldName: 'extra2',
            minWidth: 150,
            maxWidth: 250,
            isResizable: true,
            onRender: (item: IServiceLetter) => {
              return FormatCurrency(item.extra2);
            }
          }
      ] as IColumn[];

      React.useEffect(() => {    
        Fetch();
      }, []);

    const Fetch = async () => {
      setIsLoading(true);
        const result = await Services?.ServiceLetter.GetAll() as IServiceLetter[];                  
        setAllServiceLetters(result);
        setServiceLetters(result);
        setIsLoading(false);

        const taxGroups = await Services?.TaxGroup.GetAll();
        setTaxGroups(taxGroups as ITaxGroup[]);
    }
    const Reset = () => {
        setSelected(undefined);
        setShowPanel(false);
    }

const CreateOrUpdate = async() => {
  
    const result = formMode == FormMode.New ? await Services?.ServiceLetter.Create(entity) : await Services?.ServiceLetter.Update(entity);

    if(result == "OK")
      {
        Fetch();
        setShowPanel(false);        
      } else
      {
        alert(result);
      }
}

    const Commands: ICommandBarItemProps[] = [
        {
            key: 'create',
            text: 'Nyt skattegrundlag',
            iconProps: { iconName: 'Add' },
            onClick: () => { 
                Reset(); setFormMode(FormMode.New); setShowPanel(true);
             },
            disabled: selected != undefined
        },
        {
          key: 'edit',
          text: 'Rediger',
          iconProps: { iconName: 'Edit' },
          onClick: () => { 
              setValue(selected as IServiceLetter); setFormMode(FormMode.Edit); setShowPanel(true);
           },
          disabled: selected === undefined
      },
        {
          key: 'downloadStakeholderFile',
          text: 'Hent udtræk til brevfletning',
          iconProps: { iconName: 'ExcelLogo16' },
          disabled: selected == undefined,
          subMenuProps: {
            items: taxgroups.filter(x => x.name !== null).map((val, i, a) => {
              return {
                key: val.id + "",
                text: val.name,
                iconProps: { iconName: 'Download' },
                onClick: () => { 
                  Services?.Export.Create({ type: 4, payload: { year: selected?.year, taxGroupId: val.id } as IServiceLetterRequest} as IExportRequest).then((exportResult) => {
                    if(exportResult !== undefined)
                    {
                      setExportResult(exportResult);                      
                    }
                  });   
                 },
              }
            })
          } as IContextualMenuProps      
        },
        {
          key: 'shareControl',
          text: 'Anpartskontrol',
          iconProps: { iconName: 'ActivateOrders' },
          subMenuProps: {
            items: taxgroups.filter(x => x.name !== null).map((val, i, a) => {
              return {
                key: val.id + "",
                text: val.name,
                iconProps: { iconName: 'Download' },
                onClick: () => { 
                  Services?.Export.Create({ type: 5, payload: { year: selected?.year, taxGroupId: val.id } as IShareControlRequest} as IExportRequest).then((exportResult) => {
                    if(exportResult !== undefined)
                    {
                      SaveFileAs(window, exportResult.name, 'Udtræk til anpartskontrol', exportResult.content, {'text/plain': ['.txt']});
                    }
                  });   
                 },
              }
            })
          } as IContextualMenuProps,
          disabled: selected == undefined,
      },
        ];

        const CreateFarMenuItems = [
          {        
            key:"search",
            onRender:() => <SearchBox 
              placeholder="Søg efter ..." 
              className={styles.searchBox} 
              onSearch={(newValue) => { onSearch(newValue); } } 
              onChange={(event?: React.ChangeEvent<HTMLInputElement>, newValue?: string) => { onSearch(newValue); }}
              onClear={ev => { onSearch(""); }}
              />
          }
        ];
    
        const onSearch = (searchTerm: string | undefined) => {
          setServiceLetters(searchTerm && searchTerm != "" ? filter(searchTerm) : allServiceletters);
        }
    
      const filter = (searchTerm: string) : IServiceLetter[] => {
        return allServiceletters.filter(i => 
          (i.year + "").toLowerCase().indexOf(searchTerm.toLocaleLowerCase()) > -1 || 
          (i.amount + "").toLowerCase().indexOf(searchTerm.toLocaleLowerCase()) > -1 ||
          (i.interests + "").toLowerCase().indexOf(searchTerm.toLocaleLowerCase()) > -1 || 
          (i.interestsExpense + "").toLowerCase().indexOf(searchTerm.toLocaleLowerCase()) > -1 || 
          (i.result + "").toLowerCase().indexOf(searchTerm.toLocaleLowerCase()) > -1
        );
      }

      const parseContentToTable = (content: string) => {
        const headerStyles = { position:"sticky", top: 0, backgroundColor:"#fff", fontWeight: '600' } as CSSProperties;
        
        const rows = content.split('\n');
        const tableRows = rows.map((row, rowIndex) => {
            const cells = row.split(';');
            return (
                <tr key={rowIndex} style={rowIndex === 0 ? headerStyles : {}}>
                    {cells.map((cell, cellIndex) => (
                        <td key={cellIndex}>{cell}</td>
                    ))}
                </tr>
            );
        });
    
        return (
            <>
              <div style={{height:"500px", overflowY:"auto"}}>
                <table>
                    <thead>
                      {tableRows.shift()}
                    </thead>
                    <tbody>
                        {tableRows.slice(1)}
                    </tbody>
                </table>
              </div>
              <div style={{marginTop:"10px"}}><b>Antal rækker i alt: {tableRows.length-1} stk.</b></div>
            </>
        );
    };
      
    return <div>
                <ListComponent commands={Commands} farItems={CreateFarMenuItems} title={'Servicebreve & anpartskontrol'}>
                  <FreezableDetailsList 
                                      items={serviceLetters}
                                      columns={COLUMNS}   
                                      setKey="keyServiceLetters"        
                                      selectionMode={SelectionMode.single}                    
                                      onSelectionChanged={(item:any) => { setSelected(item); }}
                                    />                
                </ListComponent>
                {showPanel && 
                    <ConfirmablePanel
                    headerText={formMode === FormMode.New ? "Nyt skattegrundlag" : "Rediger"}
                    isOpen={true}                           
                    isLightDismiss={false}
                    isBlocking={true}
                    onDismiss={(ev?: React.SyntheticEvent<HTMLElement> | KeyboardEvent) => { Reset(); }}              
                    closeButtonAriaLabel="Luk panel"
                    onRenderFooterContent={() => {
                      return <PrimaryButton text={formMode == FormMode.New ? "Opret skattegrundlag" : "Opdater skattegrundlag"} onClick={() => { CreateOrUpdate() }} disabled={entity.year === undefined || entity.amount === undefined} />
                    }}
                    // type={PanelType.custom} customWidth='1000px' >
                    type={PanelType.medium}>
                        {/* <Dropdown label='Vælg skattegruppe' onChange={(ev, option) => { setValue(option?.data) }} options={taxgroups.map((val, i, a) => { return { key: val.id, text: val.name, data: val } as IDropdownOption; })}/> */}
                        <MessageBar messageBarType={MessageBarType.info}>
                          Indtast alle oplysninger <b>i alt</b> for hele vindmøllelauget i det respektive skatteår.
                        </MessageBar>
                        {formMode === FormMode.New && <Dropdown label='Start fra tidligere år' onChange={(ev, option) => { setValue(option?.data) }} options={serviceLetters.slice(0, 5).map((val, i, a) => { return { key: val.year, text: val.year, data: val } as IDropdownOption; })}/> }
                        <TextField label={'Skatteår'} value={entity.year} onChange={(ev, newValue) => { updateEntity({year: newValue}) }} />
                        <div style={{display:"grid", gridTemplateColumns:"auto auto", gap:"10px", marginTop:"10px"}}>
                          <EditableNumberTextField required label={'Elsalg'} value={entity.amount + ""} prefix='kr.' onValueChanged={(newValue: number) => { updateEntity({amount: newValue}) }} />
                          <EditableNumberTextField required label={'Renteindtægter'} value={entity.interests + ""} prefix='kr.' onValueChanged={(newValue: number) => { updateEntity({interests: newValue}) }} />
                          <EditableNumberTextField required label={'Renteudgifter'} value={entity.interestsExpense + ""} prefix='kr.' onValueChanged={(newValue: number) => { updateEntity({interestsExpense: newValue}) }} />
                          <EditableNumberTextField label={'Elsalg inkl. renter'} value={entity.elsalgInclRenter + ""} prefix='kr.' onValueChanged={(newValue: number) => { updateEntity({elsalgInclRenter: newValue}) }} />
                          <EditableNumberTextField label={'Resultat eksl. renter og afskrivning'} value={entity.resExclRenterAfskr + ""} prefix='kr.' onValueChanged={(newValue: number) => { updateEntity({resExclRenterAfskr: newValue}) }} />
                          <EditableNumberTextField label={'Afskrivninger'} value={entity.depreciation + ""} prefix='kr.' onValueChanged={(newValue: number) => { updateEntity({depreciation: newValue}) }} />                        
                        </div>
                        <EditableNumberTextField label={'Resultat'} value={ entity.result + ""} prefix='kr.' onValueChanged={(newValue: number) => { updateEntity({result: newValue}) }} />
                        <div style={{display:"grid", gridTemplateColumns:"auto auto", gap:"10px"}}>
                          <EditableNumberTextField label={'Underskud fra tidligere år'} value={entity.carriedForwardDeficit + ""} prefix='kr.' onValueChanged={(newValue: number) => { updateEntity({carriedForwardDeficit: newValue}) }} />
                          <EditableNumberTextField label={'Underskud næste år'} value={entity.underskNaesteAar + ""} prefix='kr.' onValueChanged={(newValue: number) => { updateEntity({underskNaesteAar: newValue}) }} />
                          <EditableNumberTextField label={'Skattemæssigt resultat'} value={entity.skattemRes + ""} prefix='kr.' onValueChanged={(newValue: number) => { updateEntity({skattemRes: newValue}) }} />
                          <EditableNumberTextField label={'Bundfradrag'} value={entity.bundfradrag + ""} prefix='kr.' onValueChanged={(newValue: number) => { updateEntity({bundfradrag: newValue}) }} />
                          <EditableNumberTextField label={'Skatteprocent'} value={entity.skattepIndPct + ""} prefix='kr.' onValueChanged={(newValue: number) => { updateEntity({skattepIndPct: newValue}) }} />
                          <EditableNumberTextField label={'Tilgang drift'} value={entity.tilgangDriftM + ""} prefix='kr.' onValueChanged={(newValue: number) => { updateEntity({tilgangDriftM: newValue}) }} />
                          <EditableNumberTextField label={'Afgang drift'} value={entity.afgangDriftM + ""} prefix='kr.' onValueChanged={(newValue: number) => { updateEntity({afgangDriftM: newValue}) }} />
                          <EditableNumberTextField label={'Aktiver'} value={entity.aktiver + ""} prefix='kr.' onValueChanged={(newValue: number) => { updateEntity({aktiver: newValue}) }} />
                          <EditableNumberTextField label={'Passiver'} value={entity.passiver + ""} prefix='kr.' onValueChanged={(newValue: number) => { updateEntity({passiver: newValue}) }} />
                          <EditableNumberTextField label={'Afskrivningsgruppe primo'} value={entity.afskrGrPrimo + ""} prefix='kr.' onValueChanged={(newValue: number) => { updateEntity({afskrGrPrimo: newValue}) }} />
                          <EditableNumberTextField label={'Afskrivningsgruppe ultimo'} value={entity.afskrGrUltimo + ""} prefix='kr.' onValueChanged={(newValue: number) => { updateEntity({afskrGrUltimo: newValue}) }} />
                          <EditableNumberTextField label={'Ekstra (1)'} value={entity.extra1 + ""} prefix='kr.' onValueChanged={(newValue: number) => { updateEntity({extra1: newValue}) }} />
                          <EditableNumberTextField label={'Ekstra (2)'} value={entity.extra2 + ""} prefix='kr.' onValueChanged={(newValue: number) => { updateEntity({extra2: newValue}) }} />
                          <EditableNumberTextField label={'Tilgang drift'} value={entity.tilgangDriftJ + ""} prefix='kr.' onValueChanged={(newValue: number) => { updateEntity({tilgangDriftJ: newValue}) }} />
                        </div>
                    </ConfirmablePanel>
                }
                {exportResult !== undefined &&
                    <Dialog 
                      hidden={false}
                      onDismiss={() => { setExportResult(undefined); }}
                      dialogContentProps={{
                        type: DialogType.normal,                        
                        title: 'Forhåndsvisning',
                        subText: 'Herunder vises indholdet af filen. Klik på "Hent fil" for at gemme filen på din computer.',
                      }}
                      modalProps={{isBlocking: true, styles: { main: { selectors: {
                        ['@media (min-width: 480px)']: {
                          width: "80vw",
                          minWidth: "80vw",
                          maxWidth: '1000px'
                        }
                      } } }}}>                      
                        {parseContentToTable(exportResult.content)}
                      <DialogFooter>
                        <PrimaryButton onClick={async () => { await SaveFileAs(window, exportResult.name, 'Udtræk til servicebreve brevfletning', exportResult.content, {'text/csv': ['.csv']}); setExportResult(undefined); }} text="Hent fil" />
                        <DefaultButton onClick={() => { setExportResult(undefined)}} text="Annuller" />
                      </DialogFooter>
                    </Dialog>
                }
           </div>
}

export default ServiceLetter;