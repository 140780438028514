//import { DefaultButton, Dropdown, IDropdownOption, Panel, PanelType, PrimaryButton, Stack, TextField, Toggle } from '@fluentui/react';
import React from 'react';
import { IBank, IBankInfo, IPayoutTransaction, IPersonBankRelationship, IShare, IStakeholder } from '../../models';
import commonStyles from '../../styles/Common.module.scss';
import { ApplicationContext } from '../../contexts/ApplicationContext';
import useObject from '../../hooks/useObject';
import { DefaultButton, DetailsList, DetailsListLayoutMode, Dropdown, Selection, IColumn, IDropdownOption, Label, Link, Panel, PanelType, Pivot, PivotItem, PrimaryButton, ScrollablePane, SelectionMode, Spinner, SpinnerSize, TextField, Toggle, CommandBar, ICommandBarItemProps } from '@fluentui/react';
import BanksPanel from '../settings/banks/BanksPanel';
import appstyles from './../../styles/App.module.scss';
import { FormMode } from '../../utils/Enums';
import Section from '../../components/Design/Section';
import BankRelationPanel from './BankRelationPanel';
import styles from './../../styles/App.module.scss';
import { format } from "date-fns";
import { ChangeBankRelationPanel } from './ChangeBankRelationPanel';
import ConfirmablePanel from '../../components/ConfirmablePanel';

interface IStakeholderPanelProps
{
    selectedStakeholder: IStakeholder | undefined;
    onChanged(stakeholder: IStakeholder): void;
    onDismiss(): void;
}

export const StakeholderPanel : React.FunctionComponent<IStakeholderPanelProps> = (props: React.PropsWithChildren<IStakeholderPanelProps>) => {
    const { Services } = React.useContext(ApplicationContext);    
    const { value: entity, updateValue: updateEntity } = useObject<IStakeholder>(props.selectedStakeholder ? props.selectedStakeholder : {} as IStakeholder);
    const [showSharesList, setShowSharesList] = React.useState<boolean>(false);
    const [showAddBankRelationPanel, setShowAddBankRelationPanel] = React.useState<boolean>(false);
    const [showChangeBankRelationPanel, setShowChangeBankRelationPanel] = React.useState<boolean>(false);
    const [selectedShares, setSelectedShares] = React.useState<IShare[]>([]);

    const loadStakeholder = async () => {
        Services?.Stakeholder.GetStakeholder(entity.personID).then((stakeholder: IStakeholder) => {
            updateEntity(stakeholder);
            setShowSharesList(stakeholder?.shares.length < 1000);
        });
    };
    
    React.useEffect(() => {
        loadStakeholder();
    }, []);

    const validateForm = () =>
    {
        return true;
    }

    const Update = async () =>
    {
        let result = await Services?.Stakeholder.UpdateStakeholder(entity);

        if(result === true)
        {
            props.onChanged(entity);
        } else
        {
            alert(result);
        }
    }

    //TODO: Get from backend, table: Skattegruppe
    const taxGroups: any[] = [{ key: 1, text: "fri" }, { key: 2, text: "Skematisk regel" }, { key: 3, text: "Erhvervsmæssig opgørelse (Anparts regel)"}, { key: 4, text: "Aktieselskaber/Anpartsselskaber"} ];

    const COLUMNS_BANK = [
      {
        key: 'relNr',
        name: 'Rel. nr.',
        fieldName: 'relNr',
        minWidth: 50,
        maxWidth: 50,
        isResizable: false,
        onRender: (item: IBankInfo) => {
          return <span>{item.id}</span>
        }
      },
        {
          key: 'regNo',
          name: 'Regnr.',
          fieldName: 'regNo',
          minWidth: 50,
          maxWidth: 50,
          isResizable: false,
          onRender: (item: IBankInfo) => {
            return <span>{item.bank.regNo}</span>
          }
        },
        {
            key: 'name',
            name: 'Banknavn',
            fieldName: 'name',
            minWidth: 150,
            maxWidth: 150,
            isResizable: true,
            onRender: (item: IBankInfo) => {
              return <span>{item.bank.name}</span>
            }
          },
        {
          key: 'accountNo',
          name: 'Kontonummer',
          fieldName: 'accountNo',
          minWidth: 125,
          maxWidth: 125,
          isResizable: true,
        }
      ] as IColumn[];
    
      const CommandBar_SHARES: ICommandBarItemProps[] = [
        {
          key: 'changeBankRelationship',
          text: 'Opdater bankrelation',
          iconProps: { iconName: 'Edit' },
          onClick: () => { setShowChangeBankRelationPanel(true); },
          disabled: selectedShares.length === 0 || selectedShares.some(x => x.transport || x.mortgage)
        },
        {
          key: 'switchTransport',
          text: 'Opdater transport',
          iconProps: { iconName: 'Edit' },
          onClick: () => {
            let shares = entity.shares;
            selectedShares.forEach((share) => {
              shares.find(x => x.shareId === share.shareId)!.transport = !share.transport;
            });
            updateEntity({shares: shares, updateShares: true});
           },
          disabled: selectedShares.length === 0
        },
        {
          key: 'switchMortage',
          text: 'Opdater håndpant',
          iconProps: { iconName: 'Edit' },
          onClick: () => { let shares = entity.shares;
            selectedShares.forEach((share) => {
              shares.find(x => x.shareId === share.shareId)!.mortgage = !share.mortgage;
            });
            updateEntity({shares: shares, updateShares: true}); },
          disabled: selectedShares.length === 0
        }
      ];

      const UpdateBankRelationshipForShares = (bankInfo: IBankInfo) => {
        selectedShares.forEach((share) => {
          let s = entity.shares.find(x => x.shareId === share.shareId);
          if(s) s.bankrelationshipId = bankInfo.id;
        });
        updateEntity({shares: entity.shares, updateShares: true});
        setShowChangeBankRelationPanel(false);
      }

      const CommandBar_Bankrelationships: ICommandBarItemProps[] = [
        {
          key: 'createBankRelationship',
          text: 'Tilføj bankrelation',
          iconProps: { iconName: 'Add' },
          onClick: () => { setShowAddBankRelationPanel(true); }          
        }
      ];

      const COLUMNS_SHARES = [
        {
          key: 'shareId',
          name: 'Andels nr.',
          fieldName: 'shareId',
          minWidth: 100,
          maxWidth: 100,
          isResizable: false,
        },
        {
          key: 'buyDate',
          name: 'Købsdato',
          fieldName: 'buyDate',
          minWidth: 70,
          maxWidth: 70,
          isResizable: false,
          onRender: (item: IShare) => {
            if(item.buyDate == undefined) return <span></span>

            return <span>{format(item.buyDate, "dd-MM-yyyy")}</span>
          }
        },
        {
          key: 'bankrelationshipId',
          name: 'Bankrelation',
          fieldName: 'bankrelationshipId',
          minWidth: 100,
          maxWidth: 100,
          isResizable: false,
          onRender: (item: IShare) => {
            return <span>{item.bankrelationshipId}</span>;
          }          
        },
        {
            key: 'transport',
            name: 'Transport',
            fieldName: 'transport',
            minWidth: 80,
            maxWidth: 80,
            isResizable: false,
            onRender: (item: IShare) => {
              return <span>{item.transport ? "Ja" : "Nej"}</span>
            }
          },
        {
          key: 'mortgage',
          name: 'Håndpant',
          fieldName: 'mortgage',
          minWidth: 80,
          maxWidth: 80,
          isResizable: false,
          onRender: (item: IShare) => {
            return <span>{item.mortgage ? "Ja" : "Nej"}</span>
          }
        },
        {
          key: 'buyRightsArrangement',
          name: 'Købsretsordning',
          fieldName: 'buyRightsArrangement',
          minWidth: 120,
          maxWidth: 120,
          isResizable: false,
          onRender: (item: IShare) => {
            return <span>{item.buyRightsArrangement ? "Ja" : "Nej"}</span>
          }
        }
      ] as IColumn[];

      const COLUMNS_PAYOUTS = [
        {
          key: 'period',
          name: 'Periode',
          fieldName: 'period',
          minWidth: 100,
          maxWidth: 100,
          isResizable: false,
        },
        {
          key: 'shareCount',
          name: 'Antal andele',
          fieldName: 'shareCount',
          minWidth: 100,
          maxWidth: 100,
          isResizable: false,
        },
        {
          key: 'approvalDate',
          name: 'Godkendelsesdato',
          fieldName: 'approvalDate',
          minWidth: 150,
          maxWidth: 150,
          isResizable: false,
          onRender: (item: IPayoutTransaction) => { return <span>{format(item.approvalDate, "dd-MM-yyyy")}</span> }
        }
      ] as IColumn[];

      const selection = React.useMemo(() => new Selection({
        onSelectionChanged: () => {        
          const currentSelection = selection.getSelection() as IShare[];
          
          setSelectedShares(currentSelection);
        }
      }), []);

    return (
        <ConfirmablePanel
            headerText={`${props.selectedStakeholder?.firstName} ${props.selectedStakeholder?.lastName}`}
            isOpen={true}                           
            isLightDismiss={false}                     
            isBlocking={true}
            onDismiss={(ev?: React.SyntheticEvent<HTMLElement> | KeyboardEvent) => { props.onDismiss() }}              
            closeButtonAriaLabel="Luk panel"
            onRenderFooterContent={() => {
            return <>
                <PrimaryButton styles={{root:{marginRight:"10px"}}} onClick={() => Update()} text="Gem ændringer" />
                <DefaultButton onClick={() => props.onDismiss()} text="Annuller" />                  
            </>
            }}
            // type={PanelType.custom}
            // customWidth='1200px'
            type={PanelType.extraLarge}
            >
              <Pivot>
                <PivotItem headerText="Stamoplysninger" itemKey="Stamoplysninger">
                <div style={{display:"grid", gridTemplateColumns:"1fr 1fr", gap:"10px", marginTop:"10px"}}>
                    <Section title='Personoplysninger' icon='FabricUserFolder'>
                        <TextField label={"Person ID"} value={entity.personID + ""} disabled={true} />                    
                        <TextField label={"CPR nr."} value={entity.cpr} onChange={(ev, newValue: string | undefined) => { console.log(newValue); updateEntity({cpr: newValue}) }} />                        
                        <TextField label={"CVR nr."} value={entity.cvr} onChange={(ev, newValue: string | undefined) => { updateEntity({cvr: newValue}) }} />
                        <TextField label={"Titel"} value={entity.title} onChange={(ev, newValue: string | undefined) => { updateEntity({title: newValue}) }} />
                        <TextField label={"Fornavn"} value={entity.firstName} onChange={(ev, newValue: string | undefined) => { updateEntity({firstName: newValue}) }} />
                        <TextField label={"Efternavn"} value={entity.lastName} onChange={(ev, newValue: string | undefined) => { updateEntity({lastName: newValue}) }} />
                        <TextField label={"Attention"} value={entity.attention} onChange={(ev, newValue: string | undefined) => { updateEntity({attention: newValue}) }} />
                        <TextField label={"Gadevej"} value={entity.address} onChange={(ev, newValue: string | undefined) => { updateEntity({address: newValue}) }} />
                        <TextField label={"Hus nr."} value={entity.houseNo} onChange={(ev, newValue: string | undefined) => { updateEntity({houseNo: newValue}) }} />
                        <TextField label={"Post nr."} value={entity.zipCode} onChange={(ev, newValue: string | undefined) => { updateEntity({zipCode: newValue}) }} />
                        <TextField label={"By"} value={entity.city} onChange={(ev, newValue: string | undefined) => { updateEntity({city: newValue}) }} />                        
                    </Section>
                    <Section title='Øvrige oplysninger' icon='ContactInfo'>
                      <TextField label={"Telefon"} type="tel" value={entity.phone} onChange={(ev, newValue: string | undefined) => { updateEntity({phone: newValue}) }} />
                        <TextField label={"Mobiltelefon"} type="tel" value={entity.cellphone} onChange={(ev, newValue: string | undefined) => { updateEntity({cellphone: newValue}) }} />
                        <TextField label={"E-mailadresse"} type={"email"} value={entity.email} onChange={(ev, newValue: string | undefined) => { updateEntity({email: newValue}) }} />                        
                        <Toggle label={"Brug e-mailadresse"} checked={entity.useEmail} onChange={(ev, checked?: boolean | undefined) => { updateEntity({useEmail: checked}) }} /> 
                        <Dropdown label={"Skattegruppe"} selectedKey={entity.taxGroupId} onChange={(ev, option: IDropdownOption | undefined, index: number | undefined) => { updateEntity({ taxGroupId: option?.key as number }); } } options={taxGroups} disabled />                        
                        <TextField label={"Ægtefælles CPR nr."} value={entity.spouseCPR} onChange={(ev, newValue: string | undefined) => { updateEntity({spouseCPR: newValue}) }} />
                        <TextField label={"Postsorteringskode"} value={entity.postSortCode} onChange={(ev, newValue: string | undefined) => { updateEntity({postSortCode: newValue}) }} />                        
                    </Section>  
                </div> 
                <div style={{marginTop:"10px"}}>
                    <Section title='Notater' icon='EditNote'>
                        <TextField multiline rows={10} value={entity.note} onChange={(ev, newValue: string | undefined) => { updateEntity({note: newValue}) }} />
                    </Section>
                </div>
                </PivotItem>
                <PivotItem headerText={`Bankrelationer & andele (${entity.shares?.length})`} itemKey="Andele">
                  <div style={{display:"grid", gridTemplateColumns:"1fr 1fr", gap:"10px", marginTop:"10px"}}>
                    <Section title={`Andele`} icon='TextDocumentShared'>
                            {entity.shares === undefined ? <Spinner size={SpinnerSize.large} /> :
                            <>                              
                              {entity.shares?.length > 1000 && <Toggle inlineLabel label="Vis/skjul liste over andele" onChange={(ev, checked) => { setShowSharesList(checked as boolean); }} />}                              
                              {showSharesList && entity.shares &&
                              <>
                                <CommandBar className={appstyles.commandBar} items={CommandBar_SHARES} styles={{root: {paddingLeft:"0px"}}} />              
                                <DetailsList
                                    styles={{root: {maxHeight:"50vh"}}}
                                    setKey='detailsListShares'
                                    selection={selection}
                                    items={entity.shares}
                                    onShouldVirtualize={ () => false }
                                    columns={COLUMNS_SHARES}
                                    selectionMode={SelectionMode.multiple}                   
                                    layoutMode={DetailsListLayoutMode.fixedColumns}
                                /> 
                                <Label style={{fontSize:"12px"}}>Pant: {entity.shares?.filter(x => x.mortgage).length} stk.</Label>
                                <Label style={{fontSize:"12px"}}>Transport: {entity.shares?.filter(x => x.transport).length} stk.</Label>
                                {entity.sharesInOtherGuilds && <Label style={{fontSize:"12px"}}>Personen har også andele i: {entity.sharesInOtherGuilds.split('|').filter(x => x !== "").map(x => <span className={styles.guildTag}>{x}</span>)}</Label>}
                                </>
                              }    
                            </>}
                            {showChangeBankRelationPanel && <ChangeBankRelationPanel onDismiss={() => { setShowChangeBankRelationPanel(false); }} selectedStakeholder={entity} selectedShares={selectedShares} onChanged={(value: IBankInfo) => { UpdateBankRelationshipForShares(value); }} />}        
                        </Section>
                        <Section title='Bankrelationer' icon='Bank'>
                        {entity.bankInfos ? 
                        <>
                        <CommandBar className={appstyles.commandBar} items={CommandBar_Bankrelationships} styles={{root: {paddingLeft:"0px"}}} />              
                        <DetailsList
                            items={entity.bankInfos}
                            setKey='detailsListBankInfos'
                            columns={COLUMNS_BANK}
                            selectionMode={SelectionMode.none}                            
                            layoutMode={DetailsListLayoutMode.justified} />
                            {/* <PrimaryButton styles={{root:{marginTop:"10px"}}} text="Tilføj bankrelation" onClick={() => { setShowAddBankRelationPanel(true); }} /> */}
                            {showAddBankRelationPanel &&                             
                                // <Panel isOpen={true} isLightDismiss={false} isBlocking={true} headerText='Tilføj ny bankrelation' type={PanelType.medium} onDismiss={(ev?: React.SyntheticEvent<HTMLElement> | KeyboardEvent) => { if((ev as any).keyCode !== 27) setShowAddBankRelationPanel(false) }}  onRenderFooterContent={() => {
                                //     return <>
                                //         <PrimaryButton styles={{root:{marginRight:"10px"}}} onClick={() => { CreateBankRelationship() }} text="Opret" />
                                //         <DefaultButton onClick={() => setShowAddBankRelationPanel(false) } text="Annuller" />                  
                                //     </>
                                //     }}>
                                //     <Dropdown label='Vælg bank' selectedKey={selectedBank?.regNo} options={banks.map((val, i, a) => { return { key: val.regNo, text: `${val.regNo} - ${val.name}` } as IDropdownOption })} onChange={(ev, option) => { setSelectedBank(banks.find(x => x.regNo == option?.key as number)) }} />
                                //     <div>Findes banken ikke? Opret ny <Link onClick={() => { setShowAddBankPanel(true); }}>her</Link></div>
                                //     {showAddBankPanel && <BanksPanel formMode={FormMode.New} selected={undefined} onDismiss={(update: boolean, hint: number) => { if(update) fetchBanks(hint); setShowAddBankPanel(false); }}  />}
                                //     <TextField label="Kontonummer" onChange={(ev, val) => { if(val !== undefined)setAccountNo(val) }} />
                                // </Panel>
                                <BankRelationPanel selectedStakeholder={props.selectedStakeholder} onChanged={() => { loadStakeholder(); setShowAddBankRelationPanel(false); }} onDismiss={() => { setShowAddBankRelationPanel(false); }} />
                            }
                            </> : <Spinner size={SpinnerSize.large} />}
                    </Section>
                    </div>
                </PivotItem>
                <PivotItem headerText={`Udbetalinger (${entity.payouts?.length})`} itemKey="Udbetalinger">                       
                  <div style={{marginTop:"10px"}}>
                      <Section title='Udbetalinger' icon='Money'>
                        <DetailsList
                            styles={{root: {maxHeight:"50vh"}}}
                            setKey='detailsListPayouts'                            
                            items={entity.payouts}
                            onShouldVirtualize={ () => false }
                            columns={COLUMNS_PAYOUTS}
                            selectionMode={SelectionMode.none}                   
                            layoutMode={DetailsListLayoutMode.fixedColumns}
                        /> 
                      </Section>                
                  </div>
                </PivotItem>
                <PivotItem headerText="Filer (0)" itemKey="Filer">                       
                  <div style={{marginTop:"10px"}}>
                      <Section title='Filer' icon='TextDocumentShared'>
                              <i>Kommer senere!</i>
                      </Section>                
                  </div>
                </PivotItem>
              </Pivot>                
        </ConfirmablePanel>
    );
}

export default StakeholderPanel;