import { CommandBar, ConstrainMode, DetailsListLayoutMode, DetailsRow, ICommandBarItemProps, IDetailsColumnRenderTooltipProps, IDetailsHeaderProps, IDetailsListProps, IDetailsRowStyles, IRenderFunction, IShimmeredDetailsListProps, Selection, mergeStyleSets, ScrollablePane, ScrollbarVisibility, SelectionMode, ShimmeredDetailsList, Stack, Sticky, StickyPositionType, TooltipHost } from '@fluentui/react';
import React from 'react';
import appstyles from '../styles/App.module.scss';

interface IFreezableDetailsListProps extends IShimmeredDetailsListProps
{
    onSelectionChanged: (item: any) => void;
    enableClickRowOpen?: boolean;
}

export const FreezableDetailsList: React.FunctionComponent<IFreezableDetailsListProps> = (props: React.PropsWithChildren<IFreezableDetailsListProps>) => {
    const [selected, setSelected] = React.useState<any | undefined>(undefined);

    const scrollClass = mergeStyleSets({
          detailsListWrapper: {
              height: '70vh',
              width: '100%',
              position: 'relative'
          },
    });

    const _renderFixedDetailsHeader: IRenderFunction<IDetailsHeaderProps> = (props, defaultRender) => {
          if (!props) {
              return null;
          }
          const onRenderColumnHeaderTooltip: IRenderFunction<IDetailsColumnRenderTooltipProps> =
              tooltipHostProps => (
                  <TooltipHost {...tooltipHostProps} />
              );
          return (
              <Sticky stickyPosition={StickyPositionType.Header} isScrollSynced>
                  {defaultRender!({
                      ...props,
                      onRenderColumnHeaderTooltip,
                  })}
              </Sticky>
          );
      }

      const _onRenderRow: IDetailsListProps['onRenderRow'] = prop => {
          const customStyles: Partial<IDetailsRowStyles> = {};
          if (prop) {
            if(props.selectionMode !== SelectionMode.none)
            {                
                return <div title={props.enableClickRowOpen ? "Klik for at åbne" : ""} style={{cursor: "pointer"}} onClick={() => { props.onSelectionChanged(prop.item); }}><DetailsRow {...prop} styles={customStyles} /></div>
            } else
            {
                return <DetailsRow {...prop} styles={customStyles} />
            }
          }
          return null;
        }

        const selection = React.useMemo(() => new Selection({
                onSelectionChanged: () => {       
                  const selectedItems = selection.getSelection() 
        
                  if(selectedItems.length == 1)
                  {
                    const currentSelection = selection.getSelection()[0];            
                    setSelected(currentSelection);
                    props.onSelectionChanged(currentSelection);
                  } else
                  {
                    setSelected(undefined);
                    props.onSelectionChanged(undefined);
                  }
                }
              }), []);
      
    return <Stack horizontal className={scrollClass.detailsListWrapper}>
    <Stack.Item>
        <ScrollablePane scrollbarVisibility={ScrollbarVisibility.auto}>                      
            <ShimmeredDetailsList
              {...props}
              onShouldVirtualize={() => false }              
              selection={selection}        
              onRenderRow={_onRenderRow?.bind(this)}
              layoutMode={DetailsListLayoutMode.fixedColumns}
              constrainMode={ConstrainMode.unconstrained}
              enableShimmer={props.items === undefined}
              onRenderDetailsHeader={_renderFixedDetailsHeader}
              setKey="set"          
            />
        </ScrollablePane>
    </Stack.Item>
  </Stack >
}

export default FreezableDetailsList;