import { ComboBox, DatePicker, DefaultButton, DetailsListLayoutMode, Dropdown, DropdownMenuItemType, IColumn, Selection, IComboBoxOption, ICommandBarItemProps, IDropdownOption, ITag, Label, MarqueeSelection, MessageBar, MessageBarType, Panel, PanelType, PrimaryButton, SearchBox, SelectionMode, ShimmeredDetailsList, TagPicker, VirtualizedComboBox, IDetailsListProps, IDetailsRowStyles, DetailsRow } from '@fluentui/react';
import React from 'react';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import { ApplicationContext } from '../contexts/ApplicationContext';
import useObject from '../hooks/useObject';
import { FormMode } from '../utils/Enums';
import styles from '../styles/App.module.scss';
import ListComponent from '../components/ListComponent';
import { format } from "date-fns";
import { DatePicker_onFormatDate } from '../utils/Helpers';
import { ISales, IShare, IStakeholder } from '../models';
import StakeholderSelection from '../components/StakeholderSelection';
import { da } from 'date-fns/locale/da';
import ConfirmablePanel from '../components/ConfirmablePanel';
import FreezableDetailsList from '../components/FreezableDetailsList';

interface ISalesProps
{

}

// interface ISalesDTO
// {
//   buyDate: Date;
//   companyId: number;
//   shareId: number;
//   created: Date;
//   buyerId: number;
//   buyerBankRelationshipId: number;
//   sellerId: number;
//   sellerBankRelationshipId: number;
// }

export const Sales: React.FunctionComponent<ISalesProps> = (props: React.PropsWithChildren<ISalesProps>) => {
    const navigate: NavigateFunction = useNavigate();  
    const { Services } = React.useContext(ApplicationContext);
    const [sales, setSales] = React.useState<ISales[]>([]);  
    const [allSales, setAllSales] = React.useState<ISales[]>([]);  
    const [selected, setSelected] = React.useState<ISales | undefined>(undefined);
    const [shares, setShares] = React.useState<IShare[]>([]);  
    const [isLoading, setIsLoading] = React.useState<boolean>(true);
    const [showPanel, setShowPanel] = React.useState<boolean>(false);
    const { value: entity, updateValue: updateEntity, setValue } = useObject<ISales>({} as ISales);
    const [seller, setSeller] = React.useState<IStakeholder>();
    const [buyer, setBuyer] = React.useState<IStakeholder>();
    
    const COLUMNS = [
        {
          key: 'buyDate',
          name: 'Dato for køb/salg',
          fieldName: 'buyDate',
          minWidth: 125,
          maxWidth: 125,
          isResizable: false,
          onRender: (item: ISales) => { return <span>{format(item.buyDate, "dd-MM-yyyy")}</span> }
        },
        {
          key: 'shareId',
          name: 'Andel',
          fieldName: 'shareId',
          minWidth: 100,
          maxWidth: 100,
          isResizable: true
        },
        {
          key: 'sellerId',
          name: 'Sælger',
          fieldName: 'sellerId',
          minWidth: 100,
          maxWidth: 100,
          isResizable: false
        },
        {
          key: 'buyerId',
          name: 'Køber',
          fieldName: 'buyerId',
          minWidth: 100,
          maxWidth: 100,
          isResizable: false
        }        
      ] as IColumn[];

      const COLUMNS_Shares = [
        {
          key: 'shareId',
          name: 'Andels nr.',
          fieldName: 'shareId',
          minWidth: 75,
          maxWidth: 75,
          isResizable: false          
        },
        {
          key: 'transport',
          name: 'Transport',
          fieldName: 'transport',
          minWidth: 75,
          maxWidth: 75,
          isResizable: false,
          onRender(item : IShare, index, column) {
            return item.transport ? "Ja" : "Nej";
          },         
        },
        {
          key: 'mortgage',
          name: 'Håndpant',
          fieldName: 'mortgage',
          minWidth: 75,
          maxWidth: 75,
          isResizable: false,
          onRender(item : IShare, index, column) {
            return item.mortgage ? "Ja" : "Nej";
          },       
        }
      ] as IColumn[];

      React.useEffect(() => {    
        Fetch();
      }, []);

      React.useEffect(() => {
        setValue(selected as any);
      }, [selected]);

const Fetch = async () => {
  setIsLoading(true);
    Services?.Sales.GetAll().then((result: any[]) => {                  
        setAllSales(result);
        setSales(result);
        setIsLoading(false);
      }); 
}
const Reset = () => {
    setSeller(undefined);
    setSelected(undefined);
    setShowPanel(false);
    setShares([]);
    updateEntity({});
}

    const Commands: ICommandBarItemProps[] = [
        {
            key: 'create',
            text: 'Nyt salg',
            iconProps: { iconName: 'Add' },
            onClick: () => { 
                Reset(); setShowPanel(true);
             },
            disabled: selected != undefined
        }
        ];

        const CreateFarMenuItems = [
          {        
            key:"search",
            onRender:() => <SearchBox 
              placeholder="Søg efter ..." 
              className={styles.searchBox} 
              onSearch={(newValue) => { onSearch(newValue); } } 
              onChange={(event?: React.ChangeEvent<HTMLInputElement>, newValue?: string) => { onSearch(newValue); }}
              onClear={ev => { onSearch(""); }}
              />
          }
        ];
    
        const onSearch = (searchTerm: string | undefined) => {
          setSales(searchTerm && searchTerm != "" ? filter(searchTerm) : allSales);
        }
    
        const filter = (searchTerm: string) : ISales[] => {
          return allSales.filter(i => 
            (format(i.buyDate, "dd-MM-yyyy", { locale: da})).toLowerCase().indexOf(searchTerm.toLocaleLowerCase()) > -1 ||
            (i.shareId + "").toLowerCase().indexOf(searchTerm.toLocaleLowerCase()) > -1 || 
            (i.sellerId + "").toLowerCase().indexOf(searchTerm.toLocaleLowerCase()) > -1 ||
            (i.buyerId + "").toLowerCase().indexOf(searchTerm.toLocaleLowerCase()) > -1
          );
        }

    const Create = async() => {
      if(window.confirm(`Er du sikker på at du vil gennemføre salget af ${entity.shareIds.length} andele?`))
      {
        const result = await Services?.Sales.Create(entity);

        if(result == "OK")
          {
            Fetch();
            setShowPanel(false);
            updateEntity({ sellerId: undefined, buyerId: undefined, buyerBankRelationshipId: undefined, buyDate : undefined, shareIds: undefined});
          } else
          {
            alert(result);
          }
      }
    }

    const selection = React.useMemo(() => new Selection({
      onSelectionChanged: () => {        
        const currentSelection = selection.getSelection() as IShare[];
        
        updateEntity({shareIds: currentSelection.map(x => x.shareId)});
      }
    }), []);

    const _onRenderRow: IDetailsListProps['onRenderRow'] = props => {      
      if (props?.item.transport === false && props?.item.mortgage === false) {
        return <DetailsRow {...props} />
      }
      
      const customStyles: Partial<IDetailsRowStyles> = { root: { color: "red" } };
      if (props?.item.transport || props?.item.mortgage) {
        return <div data-selection-disabled={true}><DetailsRow {...props} styles={customStyles} /></div>
      }
      return null;
    }

    return <div>
                <ListComponent commands={Commands} farItems={CreateFarMenuItems} title={'Køb & salg'}>
                <FreezableDetailsList 
                  items={sales}
                  columns={COLUMNS}   
                  setKey="keyPayouts"
                  selectionMode={SelectionMode.none}      
                  onSelectionChanged={(item:any) => {  }}
                />
                </ListComponent>
                {showPanel && 
                    <ConfirmablePanel
                    headerText={"Opret nyt salg"}
                    isOpen={true}                           
                    isLightDismiss={false}
                    isBlocking={true}
                    onDismiss={(ev?: React.SyntheticEvent<HTMLElement> | KeyboardEvent) => { Reset(); }}              
                    closeButtonAriaLabel="Luk panel"
                    onRenderFooterContent={() => {
                      return <PrimaryButton text="Gem og afslut salg" onClick={() => { Create() }} disabled={entity.buyDate === undefined} />
                    }}
                    type={PanelType.medium}>                        
                        <StakeholderSelection disabled={false} label="Sælger" OnStakeholderSelected={(selection: IStakeholder) => { setSeller(selection); updateEntity({sellerId: selection.personID, shareIds: []});  if(selection.shares) setShares(selection.shares); }} />                        
                        {seller && 
                          <>                        
                            <Label>Vælg andele</Label>
                            <ShimmeredDetailsList
                            key={"shares_"}
                            selection={selection}
                            onShouldVirtualize={() => false }
                            items={shares}
                            columns={COLUMNS_Shares}
                            selectionMode={SelectionMode.multiple}
                            onRenderRow={_onRenderRow}
                            className={styles.sharesList}
                            layoutMode={DetailsListLayoutMode.justified}
                            isHeaderVisible={true}
                            enableShimmer={shares.length == 0}
                            setKey="sharesSelected"
                            />
                            {entity.shareIds?.length > 0 && <MessageBar messageBarType={MessageBarType.info}>Der er markeret i alt <b>{entity?.shareIds?.length} andele</b></MessageBar>}
                            <StakeholderSelection disabled={entity.sellerId === undefined} label="Køber" OnStakeholderSelected={(selection: IStakeholder) => { setBuyer(selection); updateEntity({buyerId: selection.personID, buyerBankRelationshipId: selection.bankInfos.length == 1 ? selection.bankInfos[0].id : undefined}); }} />
                            <Dropdown disabled={buyer === undefined} label="Købers bank" options={buyer === undefined ? [] : buyer.bankInfos.map((val, i, a) => { return { key: val.id, text: `${val.bank.regNo} - ${val.accountNo}` } as IDropdownOption })} onChange={(ev, option) => { updateEntity({buyerBankRelationshipId: option?.key as number}); }} selectedKey={entity.buyerBankRelationshipId} />
                            <DatePicker disabled={entity.sellerId === undefined} label="Dato for salg" formatDate={DatePicker_onFormatDate} value={entity.buyDate} onSelectDate={(date) => { updateEntity({buyDate: date as Date}); }} />            
                          </>
                        }
                      </ConfirmablePanel>
                    }
           </div>
}

export default Sales;