import { IPanelProps, Panel } from '@fluentui/react';
import * as React from 'react';

export interface IConfirmablePanelProps extends IPanelProps {
    onDismiss: () => void;
}

const ConfirmablePanel: React.FunctionComponent<IConfirmablePanelProps> = (props: React.PropsWithChildren<IConfirmablePanelProps>) => {
    return <Panel {...props} onDismiss={(ev?: React.SyntheticEvent<HTMLElement> | KeyboardEvent) => { if((ev as any).keyCode !== 27 && window.confirm('Vil du lukke uden at gemme?') === true) props.onDismiss() }} />
}

export default ConfirmablePanel;