import { Checkbox, CommandBar, DatePicker, DefaultButton, Dropdown, ICommandBarItemProps, IDropdownOption, Link, MessageBar, MessageBarType, Panel, PanelType, PrimaryButton, TextField } from '@fluentui/react';
import React from 'react';
import appstyles from '../styles/App.module.scss';
import BanksPanel from '../settings/banks/BanksPanel';
import { IBank, IBankInfo, IPersonBankRelationship, IShare, IStakeholder } from '../../models';
import { FormMode } from '../../utils/Enums';
import useObject from '../../hooks/useObject';
import { ApplicationContext } from '../../contexts/ApplicationContext';
import ConfirmablePanel from '../../components/ConfirmablePanel';

interface IChangeBankRelationPanelProps
{
     selectedStakeholder: IStakeholder;
     selectedShares: IShare[];
     onChanged(value: IBankInfo): void;
     onDismiss(): void;
}

export const ChangeBankRelationPanel: React.FunctionComponent<IChangeBankRelationPanelProps> = (props: React.PropsWithChildren<IChangeBankRelationPanelProps>) => {
    const [selectedBank, setSelectedBank] = React.useState<IBankInfo | undefined>(props.selectedShares.every(x => x.bankrelationshipId === props.selectedShares[0].bankrelationshipId) ? props.selectedStakeholder.bankInfos.find(x => x.id === props.selectedShares[0].bankrelationshipId) : undefined);

    return (<ConfirmablePanel isOpen={true} isLightDismiss={false} isBlocking={true} headerText='Opdater bankrelation' type={PanelType.medium} onDismiss={(ev?: React.SyntheticEvent<HTMLElement> | KeyboardEvent) => { props.onDismiss(); }}  onRenderFooterContent={() => {
        return <>
            <PrimaryButton disabled={selectedBank === undefined} styles={{root:{marginRight:"10px"}}} onClick={() => { props.onChanged(selectedBank as IBankInfo) }} text="Gem" />
            <DefaultButton onClick={() => props.onDismiss() } text="Annuller" />                  
        </>
        }}>
            <Dropdown style={{marginBottom:"5px"}} label='Vælg bank' selectedKey={selectedBank?.id} options={props.selectedStakeholder?.bankInfos?.map((val, i, a) => { return { key: val.id, text: `${val.bank.name} (${val.bank.regNo} - ${val.accountNo})` } as IDropdownOption })} onChange={(ev, option) => { setSelectedBank(props.selectedStakeholder?.bankInfos.find(x => x.id == option?.key as number)) }} />
        </ConfirmablePanel>
    );
}
