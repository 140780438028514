import * as React from 'react';
import { RemoveLastNCharactersFromString, FormatCurrency } from "../utils/Helpers";
import { TextField } from '@fluentui/react';

export interface IEditableNumberTextFieldProps extends Omit<Omit<Omit<Omit<Omit<Omit<React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>, 'onChange'>, 'onBlur'>, 'onKeyDown'>, 'value'>, 'defaultValue'>, 'onFocus'> {
    label?: string;
    value?: string;
    onValueChanged?: (value: number) => void,    
    showDecimal?: boolean,
    suffix?: string;
}

const ALLOWED_KEY_INPUTS = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9", "Backspace", "Delete", "ArrowLeft", "ArrowRight", "Tab"];

const EditableNumberTextField: React.FunctionComponent<IEditableNumberTextFieldProps> = (props: React.PropsWithChildren<IEditableNumberTextFieldProps>) => {
    const { value, onValueChanged, showDecimal } = props;
    const [isEdit, setIsEdit] = React.useState<boolean>(false);
    const [_curVal, set_curVal] = React.useState<string>();

    let formattedValue: string = value ?? "0";

    if (formattedValue == "0") {
        formattedValue = "0";
    } else {
        if (showDecimal == null || showDecimal == false) {
            formattedValue = RemoveLastNCharactersFromString(FormatCurrency(parseFloat(formattedValue.replace(/[.](?=.*[.])/g, '')), ','), 3)
        } else {
            formattedValue = FormatCurrency(parseFloat(formattedValue.replace(/\.(?=\d{3})/g, '')), ',')
        }
    }

    return (
            <TextField type="text"
                {...props}     
                value={isEdit ? _curVal : formattedValue}
                suffix={props.suffix}
                onKeyDown={(e) => {
                    if (!!!ALLOWED_KEY_INPUTS.some((key) => e.key === key) && !!!(e.key === "," && showDecimal)) {
                        e.preventDefault();
                    }
                }}
                onBlur={(e) => {             
                    if(_curVal !== "")
                        {
                            const val: number = (e.target.value == "" || e.target.value == "-") ? 0 : parseFloat(e.target.value.replace(',', '.').replace(/\.(?=\d{3})/g, ''));
                            setIsEdit(false);
                            if(props.onValueChanged) props.onValueChanged(val);
                        }       
                        
                }}
                onFocus={(e) => { set_curVal(formattedValue == "-" ? "" : formattedValue); setIsEdit(true); e.target.select(); }}
                onChange={(ev, val) => {
                    set_curVal(val);
                }}
            />
    )
}

export default EditableNumberTextField;