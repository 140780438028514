import React from 'react';
import { DatePicker, Dropdown, IColumn, ICommandBarItemProps, IContextualMenuProps, IDropdownOption, Label, PanelType, PrimaryButton, SearchBox, SelectionMode, TextField } from '@fluentui/react';
import ListComponent from '../components/ListComponent';
import { FormMode } from '../utils/Enums';
import { ApplicationContext } from '../contexts/ApplicationContext';
import { IGuild, IPayout } from '../models';
import useObject from '../hooks/useObject';
import styles from '../styles/App.module.scss';
import { format } from "date-fns";
import { da } from 'date-fns/locale/da';
import { DatePicker_onFormatDate, SaveFileAs } from '../utils/Helpers';
import { useAuthenticationContext } from '../hooks/useAuthenticationContext';
import { IExportRequest, IPayoutLetterRequest } from '../services/ExportService';
import { CreatePayoutReceipt } from '../utils/PdfCreator';
import ConfirmablePanel from '../components/ConfirmablePanel';
import FreezableDetailsList from '../components/FreezableDetailsList';

interface IPayoutProps
{

}

export const Payout: React.FunctionComponent<IPayoutProps> = (props: React.PropsWithChildren<IPayoutProps>) => {    
    const { Services } = React.useContext(ApplicationContext);
    const [allPayouts, setAllPayouts] = React.useState<IPayout[]>([]);  
    const [payouts, setPayouts] = React.useState<IPayout[]>([]);  
    const [selected, setSelected] = React.useState<IPayout | undefined>(undefined);
    const [isLoading, setIsLoading] = React.useState<boolean>(true);
    const [showPanel, setShowPanel] = React.useState<boolean>(false);
    const { value: entity, updateValue: updateEntity, setValue } = useObject<IPayout>({} as IPayout);
    const { guild, guildStats } = useAuthenticationContext();
    const [formMode, setFormMode] = React.useState<FormMode>(FormMode.New);
    
    const COLUMNS = [
        {
          key: 'period',
          name: 'Periode',
          fieldName: 'period',
          minWidth: 100,
          maxWidth: 100,
          isResizable: false
        },
        {
          key: 'dispositionDate',
          name: 'Dispositionsdato',
          fieldName: 'dispositionDate',
          minWidth: 125,
          maxWidth: 125,
          isResizable: false,
          onRender: (item: IPayout) => { if(item.dispositionDate !== null) return <span>{format(item.dispositionDate, "dd-MM-yyyy")}</span> }
        },
        {
          key: 'amount',
          name: 'Udbetaling pr. andel',
          fieldName: 'amount',
          minWidth: 150,
          maxWidth: 150,
          isResizable: false
        },
        {
          key: 'extra',
          name: 'Ekstra pr. andel',
          fieldName: 'extra',
          minWidth: 150,
          maxWidth: 150,
          isResizable: false
        },
        {
            key: 'start',
            name: 'Startdato',
            fieldName: 'start',
            minWidth: 125,
            maxWidth: 125,
            isResizable: false,
            onRender: (item: IPayout) => { return <span>{format(item.start, "dd-MM-yyyy")}</span> }
          },
          {
            key: 'end',
            name: 'Slutdato',
            fieldName: 'end',
            minWidth: 125,
            maxWidth: 125,
            isResizable: false,
            onRender: (item: IPayout) => { return <span>{format(item.end, "dd-MM-yyyy")}</span> }
          }
      ] as IColumn[];

      React.useEffect(() => {       
        Fetch();
      }, []);

      // React.useEffect(() => {
      //   setValue(selected as IPayout);
      // }, [selected]);

        const Fetch = async () => {
          setIsLoading(true);
            Services?.Payout.GetAll().then((result: IPayout[]) => {          
                setAllPayouts(result);
                setPayouts(result);
                setIsLoading(false);
            }); 
        }

    const Reset = () => {
        // setValue(undefined);
        setShowPanel(false);
    }
      const Commands: ICommandBarItemProps[] = [
        {
            key: 'create',
            text: 'Ny udbetaling',
            iconProps: { iconName: 'Add' },
            onClick: () => { 
                Reset(); setFormMode(FormMode.New); setShowPanel(true);
             },
            disabled: selected !== undefined
        },
        {
          key: 'edit',
          text: 'Rediger',
          iconProps: { iconName: 'Edit' },
          onClick: () => { 
              setValue(selected as IPayout); setFormMode(FormMode.Edit); setShowPanel(true);
           },
          disabled: selected === undefined
        },
        {
            key: 'downloadPayoutFile',
            text: 'Hent udbetalingsfil',
            iconProps: { iconName: 'Download' },            
            disabled: selected == undefined,
            subMenuProps: {
                items: [
                  {
                    key: 'pbs',
                    text: 'PBS',
                    iconProps: { iconName: 'Download' },
                    onClick: () => { 
                      Services?.Export.Create({ type: 2, payload: { period: selected?.period, } as IPayoutLetterRequest} as IExportRequest).then((exportResult) => {
                        if(exportResult !== undefined)
                        {
                          SaveFileAs(window, exportResult.name, 'Udbetalingsfil til PBS', exportResult.content, {'text/text': ['.pbs']});
                        }
                      }); 
                     },
                  },
                  {
                    key: 'nordea',
                    text: 'Nordea (kommer snart)',
                    iconProps: { iconName: 'Download' },
                    onClick: () => { 
                      Services?.Export.Create({ type: 3, payload: { period: selected?.period, } as IPayoutLetterRequest} as IExportRequest).then((exportResult) => {
                        if(exportResult !== undefined)
                        {
                          SaveFileAs(window, exportResult.name, 'Udbetalingsfil til Nordea', exportResult.content, {'text/text': ['.xml']});
                        }
                      }); 
                     },
                    disabled: true
                  },
                ],
              } as IContextualMenuProps,
        },
        {
            key: 'downloadStakeholderFile',
            text: 'Hent udtræk til brevfletning',
            iconProps: { iconName: 'ExcelLogo16' },
            onClick: () => { 
              Services?.Export.Create({ type: 1, payload: { period: selected?.period, } as IPayoutLetterRequest} as IExportRequest).then((exportResult) => {
                if(exportResult !== undefined)
                {
                  SaveFileAs(window, exportResult.name, 'Udtræk til udbetaling brevfletning', exportResult.content, {'text/plain': ['.txt']});
                }
              });  
             },
            disabled: selected == undefined,
        },
        {
            key: 'downloadPdf',
            text: 'Hent kvittering til regnskab',
            iconProps: { iconName: 'PDF' },
            onClick: async () => {               
               const exportResult = await Services?.Export.Create({ type: 10, payload: { period: selected?.period, } as IPayoutLetterRequest} as IExportRequest);
               if(exportResult !== undefined)
               {                
                 const pdfBlob = await CreatePayoutReceipt(guild as IGuild, exportResult.content);
                 SaveFileAs(window, 'Udbetalingskvittering.pdf', 'Kvittering til regnskab', pdfBlob, {'application/pdf': ['.pdf']});
               }
            },
            disabled: selected == undefined,
        }
        ];

        const CreateFarMenuItems = [
          {        
            key:"search",
            onRender:() => <SearchBox 
              placeholder="Søg efter ..." 
              className={styles.searchBox} 
              onSearch={(newValue) => { onSearch(newValue); } } 
              onChange={(event?: React.ChangeEvent<HTMLInputElement>, newValue?: string) => { onSearch(newValue); }}
              onClear={ev => { onSearch(""); }}
              />
          }
        ];
    
        const onSearch = (searchTerm: string | undefined) => {
          setPayouts(searchTerm && searchTerm != "" ? filter(searchTerm) : allPayouts);
        }
    
        const filter = (searchTerm: string) : IPayout[] => {
          return allPayouts.filter(i => 
            (i.period + "").toLowerCase().indexOf(searchTerm.toLocaleLowerCase()) > -1 || 
            (i.amount + "").toLowerCase().indexOf(searchTerm.toLocaleLowerCase()) > -1 ||
            (i.extra + "").toLowerCase().indexOf(searchTerm.toLocaleLowerCase()) > -1 ||
            (format(i.start, "dd-MM-yyyy", { locale: da})).toLowerCase().indexOf(searchTerm.toLocaleLowerCase()) > -1 ||
            (format(i.end, "dd-MM-yyyy", { locale: da})).toLowerCase().indexOf(searchTerm.toLocaleLowerCase()) > -1           
          );
        }

      const CreateOrUpdate = async() => {
  
        const result = formMode == FormMode.New ? await Services?.Payout.Create(entity) : await Services?.Payout.Update(entity);
    
        if(result == "OK")
          {
            Fetch();
            setShowPanel(false);        
          } else
          {
            alert(result);
          }
    }
    
      
    return <div>
                <ListComponent commands={Commands} farItems={CreateFarMenuItems} title={'Udbetalinger'}>
                  <FreezableDetailsList 
                    items={payouts}
                    columns={COLUMNS}   
                    setKey="keyPayouts"        
                    selectionMode={SelectionMode.single}                    
                    onSelectionChanged={(item:any) => { setSelected(item); }}
                  />
                </ListComponent>
                {showPanel && 
                    <ConfirmablePanel
                    headerText={formMode === FormMode.New ? "Ny udbetaling" : "Rediger"}
                    isOpen={true}                           
                    isLightDismiss={false}
                    isBlocking={true}
                    onDismiss={(ev?: React.SyntheticEvent<HTMLElement> | KeyboardEvent) => { Reset(); }}              
                    closeButtonAriaLabel="Luk panel"
                    onRenderFooterContent={() => {
                      return <PrimaryButton text={formMode == FormMode.New ? "Opret udbetaling" : "Opdater udbetaling" } onClick={() => { CreateOrUpdate() }} disabled={entity.dispositionDate === undefined || entity.period === undefined || entity.amount === undefined || entity.start === undefined || entity.end === undefined || entity.period.indexOf("_") == -1 } />
                    }}
                    type={PanelType.medium}>
                        <div>
                            {formMode === FormMode.New && <Dropdown label='Start fra tidligere udbetaling' onChange={(ev, option) => { setValue(option?.data) }} options={payouts.slice(0, 5).map((val, i, a) => { return { key: val.period, text: val.period, data: val } as IDropdownOption; })}/> }
                            <div style={{display:"grid", gridTemplateColumns:"auto auto", gap:"10px"}}>
                              <TextField label={'Navn på periode'} onGetErrorMessage={(value: string) => {
                                const regex = /^\d{4}_\d+$/;
                                if (!regex.test(value)) return "Periode skal angives som Årstal_X - eks. 2024_1";
                                else return ""; }} required value={entity?.period} placeholder='Eks. 2024_1' onChange={(ev, newValue: string | undefined ) => { updateEntity({period: newValue}) }} />
                              <TextField label={'Sats pr. andel'} required value={new Intl.NumberFormat('da').format(entity?.amount)} type="number" prefix='kr.' onChange={(ev, newValue: string | undefined ) => { updateEntity({amount: Number.parseFloat(newValue + "")}) }} />
                              <TextField label={'Ekstra pr. andel'} value={new Intl.NumberFormat('da').format(entity?.extra)} type="number" prefix='kr.' onChange={(ev, newValue: string | undefined ) => { updateEntity({extra: Number.parseFloat(newValue + "")}) }} />
                              <TextField label={`${new Intl.NumberFormat('da').format(guildStats?.shareCount as number)} andele - i alt:`} disabled styles={{fieldGroup: { border:"1px solid rgb(96, 94, 92) !important" }}} value={new Intl.NumberFormat('da').format(guildStats?.shareCount as number * (entity.amount+entity.extra)) + ""} prefix='kr.' />                        
                              <div>
                                <Label>Periode - start <span className={styles.required}>*</span></Label>
                                <DatePicker value={entity.start} formatDate={DatePicker_onFormatDate} onSelectDate={(date) => { updateEntity({start: date as Date}); }} />            
                              </div>
                              <div>
                                <Label>Periode - slut <span className={styles.required}>*</span></Label>
                                <DatePicker value={entity.end} formatDate={DatePicker_onFormatDate} onSelectDate={(date) => { updateEntity({end: date as Date}); }} />                                    
                              </div>
                            </div>                            
                            <div style={{display:"grid", gridTemplateColumns:"auto auto", gap:"10px"}}>
                            </div>
                            <div>
                              <Label>Dispositionsdato <span className={styles.required}>*</span></Label>
                              <DatePicker value={entity.dispositionDate as Date} formatDate={DatePicker_onFormatDate} onSelectDate={(date) => { updateEntity({dispositionDate: date as Date}); }} />                
                            </div>                            
                        </div>
                    </ConfirmablePanel>
                }        
           </div>
}

export default Payout;